<template>
    <div class="space client-content-padding ssmb">
        
        <SpaceCourse
            :title="data.course.title"
            :description="data.course.excerpt"
            :thumbnail="data.course.thumbnail"
            :tags="data.course.tags"
            :button-label="labels.login_register"
            :a-tag="true"
            a-link="/login"

        />
        <div class="ssmb-body">
            <div class="space-navigation">
                <a
                    v-for="(link, index) in navigationLinks"
                    :href="link.url"
                    :class="{link: true, active: data.currentTab == link.name ? true : false, ['no-focus-outline']: true}"
                    :key="`space-nav-${index}`"
                >
                    <span>{{ link.label }}</span>
                    <div v-if="index + 1 != navigationLinks.length" class="line"></div>
                </a> 
            </div>
            <div class="space-content">
                <component :is="tabComponent" :labels="labels" :mbundle="data.course"></component>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    name: 'SpaceSingleMonetizationBundle',
    components: {
        SpaceCourse: () => import('../../components/SpaceCourse.vue'),
        SpaceSingleMbundleCourse: () => import('./SpaceSingleMonetizationBundle/SpaceSingleMbundleCourse.vue'),
        SpaceSingleMbundleDetails: () => import('./SpaceSingleMonetizationBundle/SpaceSingleMbundleDetails.vue'),
        SpaceSingleMbundleHome: () => import('./SpaceSingleMonetizationBundle/SpaceSingleMbundleHome.vue'),
        SpaceSingleMbundleAuthors: () => import('./SpaceSingleMonetizationBundle/SpaceSingleMbundleAuthors.vue')
    },
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            labels: {
                register: 'Register',
                home: 'Home',
                course: 'Course',
                details: 'Details',
                authors: 'Authors',
                login_register: 'Login / Register'
            }
        }
    },
    methods: {

    },
    computed: {
        tabComponent() {
            if (!this.data.currentTab?.length || this.data.currentTab == 'home') return 'SpaceSingleMbundleHome';

            let result = 'SpaceSingleMbundleHome';

            switch (this.data.currentTab) {
                case 'home':
                    result = 'SpaceSingleMbundleHome';
                    break;
                case 'course':
                    result = 'SpaceSingleMbundleCourse';
                    break;
                case 'details':
                    result = 'SpaceSingleMbundleDetails';
                    break;
                case 'authors':
                    result = 'SpaceSingleMbundleAuthors';
                    break;
            }

            return result;
        },
        navigationLinks() {
            return [
                {
                    url: `/bundle/${this.data.course._id}/home`,
                    name: 'home',
                    label: this.labels.home
                },
                {
                    url: `/bundle/${this.data.course._id}/course`,
                    name: 'course',
                    label: this.labels.course
                },
                {
                    url: `/bundle/${this.data.course._id}/details`,
                    name: 'details',
                    label: this.labels.details
                },
                {
                    url: `/bundle/${this.data.course._id}/authors`,
                    name: 'authors',
                    label: this.labels.authors
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../styles/spaces_common.scss';

.space {
    &.ssmb{
        padding:0;
        .ssmb-body{
            padding:0 30px;
        }
    }
    &-navigation {
        display: flex;
        width: 100%;
        background-color: #fff;
        box-shadow: $box_shadow;
        margin: 2rem 0;
        padding: 1rem;
        justify-content: center;

        a {
            display: flex;

            span {
                transition-property: color, background-color;
                transition-duration: 300ms;
                transition-timing-function: linear;
                padding: 5px 15px;
                border-radius: 5px;
                background-color: rgba(0,0,0,0.03);
                color: $focus_color;
            }

            div.line {
                width: 1px;
                height: 100%;
                background-color:rgba(0,0,0,0.1);
                margin-left: 15px;
                margin-right: 15px;
            }

            &:last-child {
                border-right: 0px;
            }

            &.active,
            &:hover {
                text-decoration: none;

                span {
                    background-color: $focus_color;
                    color: $color_over_focus;
                    text-decoration: none;
                }
            }
        }
    }

    &-content {
        @include client-margin;
    }
}
</style>